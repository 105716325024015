class Signaling {
    constructor(onSignal = () => null) {
        this.doNotReopen = false;
        this.onSignal = onSignal;

        console.log("Signaling init")

        this.queuedSignals = [];

        this.ws = null;
        this.connect();
    }

    connect() {
        if (this.ws && this.ws.readyState !== 3) {
            console.warn("Tried to connect to signaling server but we already have a connection open");
            return;
        }

        if (this.doNotReopen) {
            console.warn("Connect was called but doNotReopen was true");
            return;
        }

        this.ws = new WebSocket(window.FFConfig.webSocketRoot);

        this.ws.onopen = () => {
            this.queuedSignals.forEach(signal => this.ws.send(signal));
            this.queuedSignals = [];
        }

        this.ws.onmessage = message => {
            console.log("Recieved signal", message.data)
            this.onSignal(JSON.parse(message.data));
        }

        this.ws.onclose = ev => {
            console.log("WebSocket to signaling server closed", ev);

            if (!this.doNotReopen) {
                const retryIn = window.FFConfig.signalingConnectionRetrySeconds;
                Notify.warning(`Connection to the signaling server closed unexpectedly, reopening in ${retryIn} seconds`);
                setTimeout(() => this.connect(), retryIn * 1000);
            }
        }

        this.ws.onerror = err => { 
            const retryIn = window.FFConfig.signalingConnectionRetrySeconds;
            Notify.warning(`Failed to connect to signaling server, retrying in ${retryIn} seconds`);

            setTimeout(() => this.connect(), retryIn * 1000);
            console.error("Error connecting to signaling server", err);
            throw err; // Let sentry pick up the error
        };
    }

    close() {
        this.doNotReopen = true;
        this.ws.close();
    }

    sendSignal(data, type = "signal") {
        const signalObject = {
            type: type,
            data: data
        };


        const stringSendData = JSON.stringify(signalObject);
        console.log("Sending signal", stringSendData);

        if (this.ws.readyState !== 1) {
            this.queuedSignals.push(stringSendData);
            return;
        }

        this.ws.send(stringSendData);
    }
}